@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400 800;
	font-display: swap;
	src: url(/static/media/JTUSjIg1_i6t8kCHKm459Wlhyw.05747808.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400 800;
	font-display: swap;
	src: url(/static/media/JTUSjIg1_i6t8kCHKm459Wdhyzbi.89711a11.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 400 800;
	font-display: swap;
	src: url(/static/media/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.d6cb9a9a.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 400 800;
	font-display: swap;
	src: url(/static/media/JTUQjIg1_i6t8kCHKm459WxRyS7m.71221d6b.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@charset "UTF-8";
@font-face {
	font-family: 'FontAwesome';
	src: url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.eot?v=4.6.3");
	src: url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
	font-weight: normal;
	font-style: normal;
}
.fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
	font-size: 1.3333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}

.fa-2x {
	font-size: 2em;
}

.fa-3x {
	font-size: 3em;
}

.fa-4x {
	font-size: 4em;
}

.fa-5x {
	font-size: 5em;
}

.fa-fw {
	width: 1.2857142857em;
	text-align: center;
}

.fa-ul {
	padding-left: 0;
	margin-left: 2.1428571429em;
	list-style-type: none;
}
.fa-ul > li {
	position: relative;
}

.fa-li {
	position: absolute;
	left: -2.1428571429em;
	width: 2.1428571429em;
	top: 0.1428571429em;
	text-align: center;
}
.fa-li.fa-lg {
	left: -1.8571428571em;
}

.fa-border {
	padding: .2em .25em .15em;
	border: solid 0.08em #eee;
	border-radius: .1em;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

.react-add-to-calendar-recurring {
	-webkit-font-smoothing: antialiased;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	position: relative;
	display: -webkit-flex;
	display: flex;
	margin-top: 1.5rem;
}
.react-add-to-calendar-recurring__wrapper {
	zoom: 1;
	cursor: pointer;
	min-width: 190px;
}
a.react-add-to-calendar-recurring__button {
	padding: 10px;
	border-radius: 3px;
	color: #FF2A68;
	font-size: 16px;
	padding-left: 45px !important;
	text-transform: uppercase;
	font-weight: bold;
}
.react-add-to-calendar-recurring__button:active span {
	outline: 1px auto #FF2A68;
}
.react-add-to-calendar-recurring__button--light {
	background-color: #fff;
}
.react-add-to-calendar-recurring__icon--right {
	padding-left: 5px;
}

.fa-caret-up {
}
.react-add-to-calendar-recurring__icon--right:before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAWCAMAAAAcqPc3AAAA5FBMVEUAAAD/AAD/AID/QID/K1X/IGDtJG3wLWnxK2P4LWn5LWzzKmv0K2r1K2r2K2j3K2bzLGj1KWn1LGj0KWf1Kmj1Kmf2Kmj2K2j0Kmj0K2j1K2f1Kmj1Kmn2KWf2K2j0K2f0K2j1Kmn1Kmf1Kmf1Kmn2Kmj1Kmj1Kmj1Kmj1Kmj2Kmj2Kmf2KWj2K2j0K2j1Kmn1Kmj1Kmf1KWj2Kmj1Kmj1K2j1Kmj1Kmj2Kmf1Kmj1Kmj1Kmj1L2z1NG/2OXP2PHT2Rnz3VYf4ZpP4bJf6ja76nLn93uj94er+6fD+7PL//P3///8zpFJMAAAAO3RSTlMAAQIEBggOERIiKCswNTY8QEtMXmJobnFzeH6AhoiJj5CXmZ6epKywtbi8vr+/wMPNz9HX4uT3+fn7/nt/Bm0AAAABYktHREtpC4VQAAAApUlEQVQoz83Qxw6CYBCF0aui2HtFwd4rNtRr7/r+D+SCqOEnce1ZfovJzAAAFAUm99SLt0BO1XXVVGK5kJAAAFGDgq4TABq0CQHA0N7jP/tAtdLmZk9DMPun7kq2Fv2IrUttklxlxZ4nNydyFUYvY5lN7q8kK9BGQd+Hn7w8nvcjO/A0l5anbQ633Zp1AG75q0ZuzyRjwu7eCUmyKt4EuTg1xikHXmCYLS0/QDbrAAAAAElFTkSuQmCC);
	position: absolute;
	left: 0;
	color: #FF2A68;
}
.react-add-to-calendar-recurring__icon--left {
	padding-right: 5px;
}
.react-add-to-calendar-recurring__dropdown {
	position: absolute;
	top: 30px;
	left: 1px;
	width: 93%;
	padding: 5px 0 5px 8px;
	box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
	border: 1px solid #a8a8a8;
	background-color: #fff;
	text-align: left;
	z-index: 1;
	border-radius: 4px;
}
.react-add-to-calendar-recurring__dropdown ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.react-add-to-calendar-recurring__dropdown ul li a {
	color: #FF2A68;
	text-decoration: none;
	font-weight: 600;
}
.react-add-to-calendar-recurring__dropdown ul li a i {
	padding-right: 10px;
}

