@charset "UTF-8";
@font-face {
	font-family: 'FontAwesome';
	src: url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.eot?v=4.6.3");
	src: url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
	font-weight: normal;
	font-style: normal;
}
.fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
	font-size: 1.3333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}

.fa-2x {
	font-size: 2em;
}

.fa-3x {
	font-size: 3em;
}

.fa-4x {
	font-size: 4em;
}

.fa-5x {
	font-size: 5em;
}

.fa-fw {
	width: 1.2857142857em;
	text-align: center;
}

.fa-ul {
	padding-left: 0;
	margin-left: 2.1428571429em;
	list-style-type: none;
}
.fa-ul > li {
	position: relative;
}

.fa-li {
	position: absolute;
	left: -2.1428571429em;
	width: 2.1428571429em;
	top: 0.1428571429em;
	text-align: center;
}
.fa-li.fa-lg {
	left: -1.8571428571em;
}

.fa-border {
	padding: .2em .25em .15em;
	border: solid 0.08em #eee;
	border-radius: .1em;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

.react-add-to-calendar-recurring {
	-webkit-font-smoothing: antialiased;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	position: relative;
	display: flex;
	margin-top: 1.5rem;
}
.react-add-to-calendar-recurring__wrapper {
	zoom: 1;
	cursor: pointer;
	min-width: 190px;
}
a.react-add-to-calendar-recurring__button {
	padding: 10px;
	border-radius: 3px;
	color: #FF2A68;
	font-size: 16px;
	padding-left: 45px !important;
	text-transform: uppercase;
	font-weight: bold;
}
.react-add-to-calendar-recurring__button:active span {
	outline: 1px auto #FF2A68;
}
.react-add-to-calendar-recurring__button--light {
	background-color: #fff;
}
.react-add-to-calendar-recurring__icon--right {
	padding-left: 5px;
}

.fa-caret-up {
}
.react-add-to-calendar-recurring__icon--right:before {
	content: url('./assets/calendar-red.png');
	position: absolute;
	left: 0;
	color: #FF2A68;
}
.react-add-to-calendar-recurring__icon--left {
	padding-right: 5px;
}
.react-add-to-calendar-recurring__dropdown {
	position: absolute;
	top: 30px;
	left: 1px;
	width: 93%;
	padding: 5px 0 5px 8px;
	box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
	border: 1px solid #a8a8a8;
	background-color: #fff;
	text-align: left;
	z-index: 1;
	border-radius: 4px;
}
.react-add-to-calendar-recurring__dropdown ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.react-add-to-calendar-recurring__dropdown ul li a {
	color: #FF2A68;
	text-decoration: none;
	font-weight: 600;
}
.react-add-to-calendar-recurring__dropdown ul li a i {
	padding-right: 10px;
}
